<template>
  <div class="relative auth-page">
    <div class="auth-image" v-if="areaItem && !!areaItem.media">
      <img :src="areaItem.media.general[0].background" v-if="!!areaItem.media.general" alt="">
      <div class="auth-image-bg d-none d-lg-block">
        <img src="@/assets/img/boxpt_background.svg" alt="">
      </div>
    </div>
    <div class="auth-content-wrapper">
      <div class="container">
        <div class="auth-content d-flex flex-column justify-content-between align-items-center flex-lg-row justify-content-lg-between align-items-lg-start">
          <div class="header-logo">
            <router-link to="/">
              <LogoBoxSVG/>
            </router-link>
          </div>

          <div class="auth-login-wrapper">
            <div class="auth-form-heading">
              <p class="auth-form-title d-none d-lg-block">Repor Password</p>
            </div>
            <div class="auth-form-wrapper">
              <FormulateForm name="reset-password" action="" class="auth-form reset-password-form" v-model="values" @submit="resetPasswordHandler">
                <div class="form-field">
                  <FormulateInput type="password" name="password" validation="required" v-model="password" placeholder="Insira a sua nova palavra-passe" />
                  <div class="form-field-icon">
                    <lockIcon/>
                  </div>
                </div>
                <div class="form-field">
                  <FormulateInput type="password" name="reset-password" validation="required" v-model="resetPassword" placeholder="Confirme a sua nova palavra-passe" />
                  <div class="form-field-icon">
                    <lockIcon/>
                  </div>
                </div>
                <div class="form-message-response-wrapper message-error" v-if="showMessage && !submitted">
                  <p>{{ formResponseMessage }}</p>
                </div>
                <button type="submit" class="generic-btn red-gradient auth-form-submit">Repor Password</button>
              </FormulateForm>
            </div>
          </div>

          <div class="auth-btn-wrapper"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import LogoBoxSVG from "@/components/svg/logo";
import lockIcon from "@/components/svg/lock.vue";

import axios from "axios";
import qs from "qs";

export default {
  data() {
    return {
      lang: this.$root.siteLang,
      areaSEO: [],
      areaItem: [],
      errors: [],
      values: {},
      titleSEO: "",
      descriptionSEO: "",
      keywordsSEO: "",
      password: '',
      resetPassword: '',
      submitted: false,
      showMessage: false,
      formResponseMessage: '',
    }
  },
  metaInfo() {
    return {
      title: this.titleSEO,
      htmlAttrs: {
        lang: this.$root.siteLang,
      },
      meta: [
        {vmid: 'description', name: 'description', content: this.descriptionSEO},
        {vmid: 'keywords', name: 'keywords', content: this.keywordsSEO},
      ]
    }
  },
  components: {
    LogoBoxSVG,
    lockIcon
  },
  methods: {
    resetPasswordHandler() {
      let validatedPassword = '';
      if ( this.password === this.resetPassword ) {
        validatedPassword = this.password;

        const queryString = window.location.search;
        const urlParams = new URLSearchParams(queryString);
        const token = urlParams.get('token');

        const requestBody = {
          token: token,
          password: this.password,
        };

        axios
            .post(process.env.VUE_APP_API_URL + 'customer/reset', qs.stringify(requestBody))
            .then((response) => {
              if (response.data.success) {
                this.$router.push({ name: '/' });
              }
            })
            .catch((e) => {
              this.errors.push(e.response.data.errors);
              this.showMessage = true;
              this.formResponseMessage = e.response.data.errors;
            });

      } else {
        this.showMessage = true;
        this.formResponseMessage = 'The passwords do not match.';
      }

    }
  },
  created() {
    //Background
    axios
        .get(process.env.VUE_APP_API_URL + 'areas/cp_28?with_category_title=0&with_seo=0&lang=' + this.lang)
        .then((response) => {
          this.areaItem = response.data;
          this.videoID = this.areaItem.media.videos[0].url.split('/').pop();
        })
        .catch((e) => {
          this.errors.push(e);
        }).finally((e) => {});
  },
};
</script>